<div class="container-fluid customer-container">

    <div class="row">

        <div class="col-md-5 customer-list">

            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="inputGroup-sizing-default">
                <i class="material-icons">
                  search
                </i>
              </span>
                </div>
                <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" placeholder="Recherche client par nom" name="search" [(ngModel)]="search" (keyup)="OnResearch($event)">
            </div>

            <div class="list">
                <div class="list-group" *ngIf="Customers">
                    <a class="list-group-item list-group-item-action" *ngFor="let customer of Customers" (click)='selectCustomer(customer)'>
                        <div class="d-flex w-100 justify-content-between">
                            <h3 class="mb-1">{{ customer.name }}</h3>
                        </div>
                        <p class="mb-1">
                            <i class="iii material-icons">
                            call
                          </i> &nbsp; {{ customer.telephone }}
                        </p>
                        <span>
                            <i class="iii material-icons">
                              mail
                            </i> &nbsp;
                            {{ customer.email }}
                        </span> &nbsp;
                        <p>
                            <i class="iii material-icons">
                            money
                          </i> &nbsp; {{ customer.avoirs }} Fcfa (avoirs)
                        </p>
                        <p>
                            <i class="iii material-icons">
                          money
                        </i> &nbsp; {{ customer.bonus }} Fcfa (bonus)
                        </p>
                    </a>
                </div>
            </div>

        </div>

        <div class="col-md-7 customer-detail">

            <div class="container error" *ngIf="!Customer_selected" align="center">
                Aucun client selectionné !
            </div>

            <div class="row" *ngIf="Customer_selected">
                <div class="container detail" align="center">
                    <h1>{{ Customer_selected.name }}</h1>
                    <p>
                        <i class="iii material-icons">
                    mail
                  </i> {{ Customer_selected.email }}
                    </p>
                    <p>
                        <i class="iii material-icons">
                    call
                  </i> {{ Customer_selected.telephone }}
                    </p>
                    <p>
                        <button class="btn btn-primary" (click)="ChoiceCustomer()">
                          Choisir Client
                        </button>
                    </p>
                    <p>
                        <button class="btn " style="background-color: rgb(0, 0, 0); color: white;" (click)="History(Customer_selected.id)">
                          Historique Achâts
                        </button>
                    </p>
                    <p>
                        <button class="btn " style="background-color: rgb(0, 0, 0); color: white;" data-toggle="modal" data-target="#exampleModalCenter">
                        <i class="material-icons">
                          add_circle
                        </i>
                        Ajouter soutrali
                      </button>
                    </p>
                </div>
            </div>

            <div class="row footer">
                <button class="btn btn-primary" type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                <i class="material-icons">
                  add_circle
                </i>
                Ajoutez Client
              </button>
            </div>
        </div>

    </div>

</div>

<!-- Modal Part -->
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">

            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel">Ajout d'un client</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <span *ngIf="success==false">Création en cours ...</span>
                    <mat-progress-bar class="bar" *ngIf="success==false" mode="indeterminate"></mat-progress-bar>
                    <span class="text-danger" *ngIf="error.errorInsertion === '500'">Une erreur est survenue. Veuillez réessayez plus tard ou contactez le service technique !</span>
                    <span class="text-warning" *ngIf="error.errorInsertion === '400'">Ce client existe déjà dans notre base de donnée !</span>
                    <span class="text-success" *ngIf="validationMessage">{{ validationMessage }}</span>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Nom</label>
                        <input type="text" name="nom" ngModel class="form-control" id="exampleInputName">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Prénom</label>
                        <input type="text" name="prenom" ngModel class="form-control" id="exampleInputFirstName">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Adresse E-mail</label>
                        <input type="email" name="email" ngModel class="form-control" id="exampleInputEmail" aria-describedby="emailHelp">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Numéro de téléphone</label>
                        <input type="text" name="tel" ngModel class="form-control" id="exampleInputTel" required>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Date de naissance</label>
                        <input type="date" name="date_naissance" ngModel class="form-control" id="exampleInputPassword1">
                    </div>
                    <div class="form-group">
                        <label for="">Sélectionnez le pays</label>
                        <select name="" class="form-control" id="" required (change)="selectCountry($event)">
                          <option value="Cote_d_Ivoire" selected="selected">Côte_d_Ivoire </option>
                          <option value="France" >France </option>
                          <option value="Afghanistan">Afghanistan </option>
                          <option value="Afrique_Centrale">Afrique_Centrale </option>
                          <option value="Afrique_du_sud">Afrique_du_Sud </option>
                          <option value="Albanie">Albanie </option>
                          <option value="Algerie">Algerie </option>
                          <option value="Allemagne">Allemagne </option>
                          <option value="Andorre">Andorre </option>
                          <option value="Angola">Angola </option>
                          <option value="Anguilla">Anguilla </option>
                          <option value="Arabie_Saoudite">Arabie_Saoudite </option>
                          <option value="Argentine">Argentine </option>
                          <option value="Armenie">Armenie </option>
                          <option value="Australie">Australie </option>
                          <option value="Autriche">Autriche </option>
                          <option value="Azerbaidjan">Azerbaidjan </option>

                          <option value="Bahamas">Bahamas </option>
                          <option value="Bangladesh">Bangladesh </option>
                          <option value="Barbade">Barbade </option>
                          <option value="Bahrein">Bahrein </option>
                          <option value="Belgique">Belgique </option>
                          <option value="Belize">Belize </option>
                          <option value="Benin">Benin </option>
                          <option value="Bermudes">Bermudes </option>
                          <option value="Bielorussie">Bielorussie </option>
                          <option value="Bolivie">Bolivie </option>
                          <option value="Botswana">Botswana </option>
                          <option value="Bhoutan">Bhoutan </option>
                          <option value="Boznie_Herzegovine">Boznie_Herzegovine </option>
                          <option value="Bresil">Bresil </option>
                          <option value="Brunei">Brunei </option>
                          <option value="Bulgarie">Bulgarie </option>
                          <option value="Burkina_Faso">Burkina_Faso </option>
                          <option value="Burundi">Burundi </option>

                          <option value="Caiman">Caiman </option>
                          <option value="Cambodge">Cambodge </option>
                          <option value="Cameroun">Cameroun </option>
                          <option value="Canada">Canada </option>
                          <option value="Canaries">Canaries </option>
                          <option value="Cap_vert">Cap_Vert </option>
                          <option value="Chili">Chili </option>
                          <option value="Chine">Chine </option>
                          <option value="Chypre">Chypre </option>
                          <option value="Colombie">Colombie </option>
                          <option value="Comores">Colombie </option>
                          <option value="Congo">Congo </option>
                          <option value="Congo_democratique">Congo_democratique </option>
                          <option value="Cook">Cook </option>
                          <option value="Coree_du_Nord">Coree_du_Nord </option>
                          <option value="Coree_du_Sud">Coree_du_Sud </option>
                          <option value="Costa_Rica">Costa_Rica </option>
                          <option value="Croatie">Croatie </option>
                          <option value="Cuba">Cuba </option>

                          <option value="Danemark">Danemark </option>
                          <option value="Djibouti">Djibouti </option>
                          <option value="Dominique">Dominique </option>

                          <option value="Egypte">Egypte </option>
                          <option value="Emirats_Arabes_Unis">Emirats_Arabes_Unis </option>
                          <option value="Equateur">Equateur </option>
                          <option value="Erythree">Erythree </option>
                          <option value="Espagne">Espagne </option>
                          <option value="Estonie">Estonie </option>
                          <option value="Etats_Unis">Etats_Unis </option>
                          <option value="Ethiopie">Ethiopie </option>

                          <option value="Falkland">Falkland </option>
                          <option value="Feroe">Feroe </option>
                          <option value="Fidji">Fidji </option>
                          <option value="Finlande">Finlande </option>
                          <option value="France">France </option>

                          <option value="Gabon">Gabon </option>
                          <option value="Gambie">Gambie </option>
                          <option value="Georgie">Georgie </option>
                          <option value="Ghana">Ghana </option>
                          <option value="Gibraltar">Gibraltar </option>
                          <option value="Grece">Grece </option>
                          <option value="Grenade">Grenade </option>
                          <option value="Groenland">Groenland </option>
                          <option value="Guadeloupe">Guadeloupe </option>
                          <option value="Guam">Guam </option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guernesey">Guernesey </option>
                          <option value="Guinee">Guinee </option>
                          <option value="Guinee_Bissau">Guinee_Bissau </option>
                          <option value="Guinee equatoriale">Guinee_Equatoriale </option>
                          <option value="Guyana">Guyana </option>
                          <option value="Guyane_Francaise ">Guyane_Francaise </option>

                          <option value="Haiti">Haiti </option>
                          <option value="Hawaii">Hawaii </option>
                          <option value="Honduras">Honduras </option>
                          <option value="Hong_Kong">Hong_Kong </option>
                          <option value="Hongrie">Hongrie </option>

                          <option value="Inde">Inde </option>
                          <option value="Indonesie">Indonesie </option>
                          <option value="Iran">Iran </option>
                          <option value="Iraq">Iraq </option>
                          <option value="Irlande">Irlande </option>
                          <option value="Islande">Islande </option>
                          <option value="Israel">Israel </option>
                          <option value="Italie">italie </option>

                          <option value="Jamaique">Jamaique </option>
                          <option value="Jan Mayen">Jan Mayen </option>
                          <option value="Japon">Japon </option>
                          <option value="Jersey">Jersey </option>
                          <option value="Jordanie">Jordanie </option>

                          <option value="Kazakhstan">Kazakhstan </option>
                          <option value="Kenya">Kenya </option>
                          <option value="Kirghizstan">Kirghizistan </option>
                          <option value="Kiribati">Kiribati </option>
                          <option value="Koweit">Koweit </option>

                          <option value="Laos">Laos </option>
                          <option value="Lesotho">Lesotho </option>
                          <option value="Lettonie">Lettonie </option>
                          <option value="Liban">Liban </option>
                          <option value="Liberia">Liberia </option>
                          <option value="Liechtenstein">Liechtenstein </option>
                          <option value="Lituanie">Lituanie </option>
                          <option value="Luxembourg">Luxembourg </option>
                          <option value="Lybie">Lybie </option>

                          <option value="Macao">Macao </option>
                          <option value="Macedoine">Macedoine </option>
                          <option value="Madagascar">Madagascar </option>
                          <option value="Madère">Madère </option>
                          <option value="Malaisie">Malaisie </option>
                          <option value="Malawi">Malawi </option>
                          <option value="Maldives">Maldives </option>
                          <option value="Mali">Mali </option>
                          <option value="Malte">Malte </option>
                          <option value="Man">Man </option>
                          <option value="Mariannes du Nord">Mariannes du Nord </option>
                          <option value="Maroc">Maroc </option>
                          <option value="Marshall">Marshall </option>
                          <option value="Martinique">Martinique </option>
                          <option value="Maurice">Maurice </option>
                          <option value="Mauritanie">Mauritanie </option>
                          <option value="Mayotte">Mayotte </option>
                          <option value="Mexique">Mexique </option>
                          <option value="Micronesie">Micronesie </option>
                          <option value="Midway">Midway </option>
                          <option value="Moldavie">Moldavie </option>
                          <option value="Monaco">Monaco </option>
                          <option value="Mongolie">Mongolie </option>
                          <option value="Montserrat">Montserrat </option>
                          <option value="Mozambique">Mozambique </option>

                          <option value="Namibie">Namibie </option>
                          <option value="Nauru">Nauru </option>
                          <option value="Nepal">Nepal </option>
                          <option value="Nicaragua">Nicaragua </option>
                          <option value="Niger">Niger </option>
                          <option value="Nigeria">Nigeria </option>
                          <option value="Niue">Niue </option>
                          <option value="Norfolk">Norfolk </option>
                          <option value="Norvege">Norvege </option>
                          <option value="Nouvelle_Caledonie">Nouvelle_Caledonie </option>
                          <option value="Nouvelle_Zelande">Nouvelle_Zelande </option>

                          <option value="Oman">Oman </option>
                          <option value="Ouganda">Ouganda </option>
                          <option value="Ouzbekistan">Ouzbekistan </option>

                          <option value="Pakistan">Pakistan </option>
                          <option value="Palau">Palau </option>
                          <option value="Palestine">Palestine </option>
                          <option value="Panama">Panama </option>
                          <option value="Papouasie_Nouvelle_Guinee">Papouasie_Nouvelle_Guinee </option>
                          <option value="Paraguay">Paraguay </option>
                          <option value="Pays_Bas">Pays_Bas </option>
                          <option value="Perou">Perou </option>
                          <option value="Philippines">Philippines </option>
                          <option value="Pologne">Pologne </option>
                          <option value="Polynesie">Polynesie </option>
                          <option value="Porto_Rico">Porto_Rico </option>
                          <option value="Portugal">Portugal </option>

                          <option value="Qatar">Qatar </option>

                          <option value="Republique_Dominicaine">Republique_Dominicaine </option>
                          <option value="Republique_Tcheque">Republique_Tcheque </option>
                          <option value="Reunion">Reunion </option>
                          <option value="Roumanie">Roumanie </option>
                          <option value="Royaume_Uni">Royaume_Uni </option>
                          <option value="Russie">Russie </option>
                          <option value="Rwanda">Rwanda </option>

                          <option value="Sahara Occidental">Sahara Occidental </option>
                          <option value="Sainte_Lucie">Sainte_Lucie </option>
                          <option value="Saint_Marin">Saint_Marin </option>
                          <option value="Salomon">Salomon </option>
                          <option value="Salvador">Salvador </option>
                          <option value="Samoa_Occidentales">Samoa_Occidentales</option>
                          <option value="Samoa_Americaine">Samoa_Americaine </option>
                          <option value="Sao_Tome_et_Principe">Sao_Tome_et_Principe </option>
                          <option value="Senegal">Senegal </option>
                          <option value="Seychelles">Seychelles </option>
                          <option value="Sierra Leone">Sierra Leone </option>
                          <option value="Singapour">Singapour </option>
                          <option value="Slovaquie">Slovaquie </option>
                          <option value="Slovenie">Slovenie</option>
                          <option value="Somalie">Somalie </option>
                          <option value="Soudan">Soudan </option>
                          <option value="Sri_Lanka">Sri_Lanka </option>
                          <option value="Suede">Suede </option>
                          <option value="Suisse">Suisse </option>
                          <option value="Surinam">Surinam </option>
                          <option value="Swaziland">Swaziland </option>
                          <option value="Syrie">Syrie </option>

                          <option value="Tadjikistan">Tadjikistan </option>
                          <option value="Taiwan">Taiwan </option>
                          <option value="Tonga">Tonga </option>
                          <option value="Tanzanie">Tanzanie </option>
                          <option value="Tchad">Tchad </option>
                          <option value="Thailande">Thailande </option>
                          <option value="Tibet">Tibet </option>
                          <option value="Timor_Oriental">Timor_Oriental </option>
                          <option value="Togo">Togo </option>
                          <option value="Trinite_et_Tobago">Trinite_et_Tobago </option>
                          <option value="Tristan da cunha">Tristan de cuncha </option>
                          <option value="Tunisie">Tunisie </option>
                          <option value="Turkmenistan">Turmenistan </option>
                          <option value="Turquie">Turquie </option>

                          <option value="Ukraine">Ukraine </option>
                          <option value="Uruguay">Uruguay </option>

                          <option value="Vanuatu">Vanuatu </option>
                          <option value="Vatican">Vatican </option>
                          <option value="Venezuela">Venezuela </option>
                          <option value="Vierges_Americaines">Vierges_Americaines </option>
                          <option value="Vierges_Britanniques">Vierges_Britanniques </option>
                          <option value="Vietnam">Vietnam </option>

                          <option value="Wake">Wake </option>
                          <option value="Wallis et Futuma">Wallis et Futuma </option>

                          <option value="Yemen">Yemen </option>
                          <option value="Yougoslavie">Yougoslavie </option>

                          <option value="Zambie">Zambie </option>
                          <option value="Zimbabwe">Zimbabwe </option>
                        </select>

                    </div>
                    <div class="form-group">
                        <label for="exampleInputPassword1">Type de client</label>
                        <select class="form-control" id="exampleFormControlSelect1" name="type_client" ngModel>
              <option value="client regulier">Client régulier</option>
              <option value="client occasionnel">Client occasionnel</option>
              <option value="demi-grossiste">Demi-grossiste</option>
              <option value="grossiste">Grossiste</option>
            </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn btn-primary">Enregistrer</button>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <form #s="ngForm" (ngSubmit)="onSubmitSoutra(s)">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Ajouter soutrali</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <span class="text-success" *ngIf="validationMessage">{{ validationMessage }}</span>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Montant</label>
                        <input type="text" name="soutra" ngModel class="form-control">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Enregistrer</button>
                </div>
            </div>
        </form>
    </div>
</div>