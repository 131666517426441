<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand">Caisse</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto " style="width: 80%;">
            <!--<form class="form-inline my-2 my-lg-0" style="width: 70%;">
        <input class="form-control mr-sm-2 ml-5" type="search" placeholder="Recherche ..." aria-label="Search" style="width: 70%;">
        <button class="btn btn-outline-primary my-2 my-sm-0" type="submit">Scan code</button>
      </form>-->
            <!--  <li class="nav-item active">
        <a class="nav-link" href="#">
          <i class="material-icons text-primary">
            signal_wifi_4_bar
          </i>
           <small>Hors Connexion</small>
        </a>
      </li>
      <li class="nav-item active">
        <a class="nav-link" href="#">
          <i class="material-icons text-primary">
            refresh
          </i>
          <small>Rafraichir</small>
        </a>
      </li> -->
        </ul>
        <div class="text-center" *ngIf="loading === true">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div *ngIf="loading === false" class="form-inline my-2 my-lg-0">
            <div class="avatar">
                <img src="https://image.freepik.com/free-vector/mysterious-mafia-man-smoking-cigarette_52683-34828.jpg" width="100%" height="100%" alt="">
            </div>
            <!--<button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>-->

            <h5 class="avatar-name" *ngIf="userConnected">
                {{ userConnected.name }}
            </h5> <br>
            <div class="d-flex flex-row">
                <a class="pr-4" (click)="logout()" style="color: black; cursor: pointer;">
                    <small style="font-weight: 800;">
                  <i class="material-icons">settings_power</i>
                  Déconnexion
                </small>
                </a>
                <a class="pl-4" (click)="closeMarket()" style="color: black; cursor: pointer;">
                    <small style="font-weight: 800;">
                <i class="material-icons">settings_power</i>
                Fermerture
              </small>
                </a>
            </div>

        </div>


    </div>
</nav>
