import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sms-detail',
  templateUrl: './sms-detail.component.html',
  styleUrls: ['./sms-detail.component.css']
})
export class SmsDetailComponent implements OnInit {
  id;
  user;
  success;
  permission;
  visibility;
  message;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  dtTrigger2: Subject<any> = new Subject<any>();
  dtOptions2: any = {};
  constructor(private authService: AuthService,private router: Router,private route: ActivatedRoute) {  }

  ngOnInit(): void {
    this.dtOptions = {
      ordering: false
    };
    this.dtOptions2 = {
      ordering: false
    };
    this.route.paramMap.subscribe(
      (params => {
        this.id = params.get('id');
      })
    );
    this.user = JSON.parse(localStorage.getItem('caissier'));
    this.onSubmit()
  }
  onSubmit() {
    this.visibility = true
    this.authService.GetSmsDetail(this.id).subscribe(
      (data) => {
        console.log(data)

        this.visibility = false
        this.permission = data.data
        this.dtTrigger.next();
      }, (err) => {
        this.visibility = false
      }
    );
  }
  updatePermission(){
    this.authService.PutPermissionDetail(this.id,this.permission).subscribe(
      (data) => {
        this.success = true;
        this.message = data.message
        console.log(data);
      }, (err) => {
      }
    );
  }
}
