<app-navbar></app-navbar>

<div class="container-fluid full-page">

    <div class="row">

        <div class="col-md-1 full-container">

            <app-sidemenu></app-sidemenu>

        </div>

        <div class="text-center container">
            <div class="text-center">
                <h1>Campagne de publicité</h1>
            </div>
            <br>
            <br>
            <br>
            <div>
                Nombre de message requis : <span class="badge badge-warning">{{userCount.length*messageNumber}} sms </span>
            </div> <br> <br>
            <div>
                Nombre de message disponible : <span class="badge badge-success" *ngIf="smsCount > userCount.length*messageNumber">{{smsCount}} sms </span>
                <span class="badge badge-danger" *ngIf="smsCount < userCount.length*messageNumber">{{smsCount}} sms </span>
            </div>

            <br>
            <div class="main ">
                <form #f="ngForm" (ngSubmit)="onSubmit(f)">

                    <div class="form-group">
                        <label for="exampleFormControlInput1">Objet</label>
                        <input type="email" ngModel name="objet" class="form-control" id="exampleFormControlInput1" placeholder="">
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Message</label>
                        <span class="badge badge-danger">{{
                          messageNumber > 1
                            ? messageNumber + " messages"
                            : messageNumber + " message"
                        }}</span
                        >&nbsp;
                        <span class="badge badge-primary">{{
                          messageLength > 1
                            ? messageLength + " caractères"
                            : messageLength + " caractère"
                        }}</span>

                        <div class="d-flex align-items-end">
                            <textarea class="form-control" maxlength="456" (keyup)="personBody()" [(ngModel)]="messagePerso" ngModel name="message" id="exampleFormControlTextarea1" rows="10" cols="50"></textarea> &nbsp;&nbsp;
                            <button type="button" (click)="metaData('[nom_client]')" class="btn btn-primary mb-2">Nom client</button>
                        </div>
                    </div>
                    <div class="lds-dual-ring" *ngIf="success === true"></div>
                    <button *ngIf="success === false" type="submit" class="btn btn-primary mb-2">Envoyer</button>
                    <div *ngIf="validationMessage" class="alert alert-success" role="alert">
                        {{validationMessage}}
                    </div>
                </form>

            </div>
        </div>

    </div>

</div>