<div class="container-fluid onglet">
  <ul class="nav nav-pills nav-fill">
    <li class="nav-item">
      <a class="nav-link elt-1" (click)="GoToDailyBook('elt-1')" style="cursor: pointer;">Point Journalier</a>
    </li>
    <li class="nav-item">
      <a class="nav-link elt-2" (click)="GoToGeneralBook('elt-2')" style="cursor: pointer;" id="active" >Vue Générale</a>
    </li>
    <li class="nav-item">
      <a class="nav-link elt-3" (click)="GoToSpecificBook('elt-3')" style="cursor: pointer;">Vue Détaillée</a>
    </li>
  </ul>
</div>
<div class="container-fluid sales-full">

  <!--<router-outlet name="child21"></router-outlet>-->
  <app-sales-general *ngIf="visibility.general === true"></app-sales-general>
  <app-sales-content *ngIf="visibility.detail === true"></app-sales-content>
  <app-sales-daily *ngIf="visibility.daily === true"></app-sales-daily>

</div>
