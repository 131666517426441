<app-navbar></app-navbar>

<div class="container-fluid full-page">

    <div class="row">

      <div class="col-md-1 full-container">

        <app-sidemenu></app-sidemenu>

      </div>

      <div class="col-md-7 full-container">

        <router-outlet name="child1"></router-outlet>

      </div>

      <div class="col-md-4 full-container ">

        <app-cart></app-cart>

      </div>

    </div>

</div>
