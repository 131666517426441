<app-navbar></app-navbar>

<div class="container-fluid full-page">

  <div class="row">

    <div class="col-md-1 full-container">

      <app-sidemenu></app-sidemenu>

    </div>

    <div class="col-md-11 full-container">

      <ul class="nav nav-pills mt-3 mb-4 nav-justified" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" >
            Factures Impayées
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" >
            Factures Payés
          </a>
        </li>
      </ul>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" >

          <div class="container-fluid general-table-sales">
            <div class="text-center" *ngIf="visibility === true">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
              <thead>
                <tr>
                  <th>NUM COMMANDE</th>
                  <th>Date </th>
                  <th>Nom & Prénoms Client</th>
                  <th>Quantité Totale Commandée</th>
                  <th>Total</th>
                  <th>Avance</th>
                  <th>Reste à payer</th>
                  <th></th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sale of sales">
                  <td>{{sale.order_number }}</td>
                  <td>{{ sale.created_at | date:'short':'UTC':'fr' }}</td>
                  <td>{{ sale.first_name }}</td>
                  <td>{{ sale.quantity }}</td>
                  <td>{{ sale.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                  <td>{{ sale.avance | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                  <td>{{ sale.reste | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                  <td>
                    <button class="btn btn-outline-info btn-sm" (click)="goToDetail(sale.id)">
                      Détails
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br><br>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" >

        <div class="container-fluid general-table-sales">
        <div class="text-center" *ngIf="visibility2 === true">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
            <table datatable class="row-border hover" [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2">
              <thead>
                <tr>
                  <th>NUM COMMANDE</th>
                  <th>Date </th>
                  <th>Nom & Prénoms Client</th>
                  <th>Quantité Totale Commandée</th>
                  <th>Total</th>
                  <th>Avance</th>
                  <th>Reste à payer</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sale of salesPaid">
                  <td>{{sale.order_number }}</td>
                  <td>{{ sale.created_at | date:'short':'UTC':'fr' }}</td>
                  <td>{{ sale.first_name }}</td>
                  <td>{{ sale.quantity }}</td>
                  <td>{{ sale.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                  <td>{{ sale.avance | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                  <td>{{ sale.reste | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                  <td>
                    <button class="btn btn-outline-info btn-sm" (click)="goToDetail(sale.id)">
                      Détails
                    </button>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>

     <!--<div class="container-fluid general-table-sales">
        <div class="text-center" *ngIf="visibility === true">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
          <thead>
            <tr>
              <th>NUM COMMANDE</th>
              <th>Date </th>
              <th>Nom & Prénoms Client</th>
              <th>Quantité Totale Commandée</th>
              <th>Total</th>
              <th>Avance</th>
              <th>Reste à payer</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sale of sales">
              <td>{{sale.order_number }}</td>
              <td>{{ sale.created_at | date:'short':'UTC':'fr' }}</td>
              <td>{{ sale.first_name }}</td>
              <td>{{ sale.quantity }}</td>
              <td>{{ sale.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
              <td>{{ sale.avance | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
              <td>{{ sale.reste | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
              <td>
                <button class="btn btn-outline-info btn-sm" (click)="goToDetail(sale.id)">
                  Détails
                </button>
              </td>
              <td>
                <button class="btn btn-outline-warning btn-sm">
                  Nouveau versement
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->


    </div>

  </div>

</div>


<!-- Modal -->
<!-- data-toggle="modal" data-target="#exampleModalScrollableD" -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <form  method="post">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Renouvellement de paiement</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="exampleInputEmail1">Entrez le montant</label>
          <input type="number" name="newMontant" [(ngModel)]="newMontant" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
          <small id="emailHelp" class="form-text text-danger">Rassurez-vous que le montant est correcte !</small>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="submit" class="btn btn-primary">Valider</button>
      </div>
    </div>
  </form>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalScrollableD" tabindex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">Détails des versements</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" align="center">
        <table class="table table-borderless" align="center">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Date</th>
              <th scope="col">Montant Versé</th>
              <th scope="col">Montant Restant</th>
            </tr>
          </thead>
          <tbody *ngIf="historyVersement">
            <tr *ngFor="let versement of historyVersement">
              <th scope="row">
                Versement n° {{ versement.num + 1}}
              </th>
              <td>
                {{ versement.created_at | date:'short':'UTC':'fr' }}
              </td>
              <td>
                {{ versement.montant | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
              </td>
              <td>
                {{ versement.reste | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
        <button type="button" class="btn btn-primary">Nouveau Versement</button>
      </div>
    </div>
  </div>
</div>
