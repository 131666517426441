<div class="row" align="center">

    <div class="col-md-12 columns" align="center" (click)="goToHome()">
        <i class="material-icons">
      home
    </i>
        <span class="sidetitle">
      Accueil
    </span>
    </div>
    <div class="col-md-12 columns" align="center" (click)="goToSales()">
        <i class="material-icons">
      monetization_on
    </i>
        <span class="sidetitle">
      Ventes
    </span>
    </div>
    <div class="col-md-12 columns" align="center" (click)="goToCustomer()">
        <i class="material-icons">
      person
    </i>
        <span class="sidetitle">
      Clients
    </span>
    </div>
    <div class="col-md-12 columns" align="center" (click)="goToOrder()">
        <i class="material-icons">
      shopping_cart
    </i>
        <span class="sidetitle pi">
      Commandes
    </span>
    </div>
    <div class="col-md-12 columns" align="center" (click)="goToFacture()">
        <i class="material-icons">
      description
    </i>
        <span class="sidetitle">
      Factures
    </span>
    </div>
    <div class="col-md-12 columns" align="center" (click)="goToReservation()">
        <i class="material-icons">
      assignment
    </i>
        <span class="sidetitle">
      Réservations
    </span>
    </div>
    <div class="col-md-12 columns" align="center" (click)="goToStatistic()">
        <i class="material-icons">
      timeline
    </i>
        <span class="sidetitle pi">
      Statistiques
    </span>
    </div>
    <div class="col-md-12 columns" align="center" (click)="goToPermission()">
        <i class="material-icons">
          tour
        </i>
        <span class="sidetitle pi">
          Permissions
        </span>
    </div>
    <div *ngIf="user && user.role === 'admin' && user.is_manager === 1" class="col-md-12 columns" align="center" (click)="goToSms()">
        <i class="material-icons">
          textsms
        </i> <br>
        <span class="sidetitle pi">
          SMS
        </span>
    </div>
    <div class="col-md-12 columns" align="center" (click)="goToInformation()">
        <i class="material-icons">
      info
    </i>
        <span class="sidetitle pi">
      Informations
    </span>
    </div>

</div>