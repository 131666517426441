<div class="container-fluid general-table-sales">
    <div class="text-center" *ngIf="visibility === true">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>



    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" *ngIf="user">
        <thead>
            <tr>
                <th *ngIf="user.role === 'admin'">Caissier</th>
                <th>NUM COMMANDE</th>
                <th>Date </th>
                <th>Nom & Prénoms Client</th>
                <th>Numéro Client</th>
                <th>Type Client</th>
                <th>Quantité Commandée</th>
                <th>Montant</th>
                <!-- <th>Total</th> -->
            </tr>
        </thead>
        <tbody *ngIf="salesDaily">
            <tr *ngFor="let sale of salesDaily">
                <td *ngIf="user.role === 'admin' && sale.caissier !== null ">{{ sale.caissier.name}}</td>
                <td *ngIf="user.role === 'admin' && sale.caissier === null " class="text-danger">NEANT</td>
                <td>{{sale.order.order.order_number }}</td>
                <td>{{ sale.order.created_at | date:'short':'UTC':'fr' }}</td>
                <td>{{ sale.client.name }}</td>
                <td>{{ sale.client.telephone }}</td>
                <td>{{ sale.client.type }}</td>
                <td>{{ sale.order.order.quantity }}</td>
                <td>{{sale.order.montant | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                <!-- <td>{{ sale.order.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td> -->
            </tr>
        </tbody>
    </table>
    <div class="container-fluid bg-dark text-light p-3 mt-5">
        <div class="row">
            <div class="col-md-1">
                <h5>Total</h5>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-1"></div>
            <div class="col-md-1"></div>
            <div class="col-md-1"></div>
            <div class="col-md-1"></div>

            <div class="col-md-2" *ngIf="quantitySum">
                <span style="float: right;">{{ quantitySum }}</span>
            </div>



            <div class="col-md-2" *ngIf="subTotal">
                <span style="float: right;">{{ subTotal | currency:'Fcfa':'symbol':'4.2-2':'fr'   }}</span>
            </div>

            <div class="col-md-2" *ngIf="!subTotal">
                <span style="float: right;">0 Fcfa</span>
            </div>


            <div class="col-md-2" *ngIf="total">
                <span style="float: right;">{{ total | currency:'Fcfa':'symbol':'4.2-2':'fr'  }}</span>
            </div>

        </div>
    </div>
</div>