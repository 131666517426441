
<div class="text-center" *ngIf="visibility === true">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div class="row m-2">
  <div class="container">
    <div class="col-md-12 p-1">
      <div class="float-right" *ngIf='details'>
        <button class="btn btn-outline-danger mr-4" (click)="removeReservation(details.reservation_number)">
          Annuler
        </button>
        <button class="btn btn-outline-warning" (click)="changeToOrder(details.reservation_number)">
          Transformer en Commande
        </button>
      </div>
    </div>
  </div>
</div>

<hr>

<div class="container-fluid">
  <mat-progress-bar *ngIf="success===false"  mode="indeterminate"></mat-progress-bar>
  <div class="alert alert-danger" *ngIf="error.product === true">
    Votre produit a été supprimé !
  </div>
  <div class="alert alert-danger" *ngIf="error.delete === true">
    {{ error.text }}
  </div>
  <div class="alert alert-info" *ngIf="changeSuccess === true">
    Votre réservation est maintenant une commande !
  </div>
  <br>
  <div class="row">

    <div class="col-md-4">
      <!-- detail -->
      <div class="card" style="width: 100%;" *ngIf="details">
        <div class="card-body">
          <h5 class="card-title">Informations Réservation</h5>
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Numéro Réservation
              <span class="badge badge-pill">
                {{ details.reservation_number }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Nom Client
              <span class="badge badge-pill">
                {{ details.first_name }}
              </span>
            </li>

            <li class="list-group-item d-flex justify-content-between align-items-center">
              Email Client
              <span class="badge badge-pill">
                {{ details.email }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Téléphone Client
              <span class="badge badge-pill">
                {{ details.phone }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Quantité Totale
              <span class="badge badge-pill">
                {{ details.quantity }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Montant Total
              <span class="badge badge-pill">
                {{ details.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Date de Réception
              <span class="badge badge-pill">
                {{ details.date_promise }}
              </span>
            </li>
          </ul>

        </div>
      </div>
      <!-- // end detail -->
    </div>

    <div class="col-md-8 table">

      <!-- produits -->
      <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        <thead>
          <tr>
            <th>Nom Produit</th>
            <th>Quantité Produit</th>
            <th>Prix unitaire</th>
            <th>Prix total</th>
            <th>Photo</th>
            <th></th>
           </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of products">
            <td>{{ product.title }}</td>
            <td>{{ product.quantity }}</td>
            <td>{{ product.price | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
            <td>{{ product.amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
            <td>
              <button class="btn btn-outline-info" data-toggle="modal" data-target="#exampleModal" (click)="viewImageProduct(product.title, product.photo)">
                Voir Image
              </button>
            </td>
            <td>
              <button class="btn btn-outline-danger" (click)="deleteProduct(product.product_id)">
                Supprimer
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- // end produits -->
    </div>

  </div>

</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Image Produit</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container" *ngIf="image && name">
          <h2><strong>{{ name }}</strong></h2>
          <img [src]=image  width="100%" height="100%" alt="" srcset="">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-2" data-dismiss="modal">Fermer</button>
        <!-- <button type="button" class="btn ">OK</button> -->
      </div>
    </div>
  </div>
</div>
