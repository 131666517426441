import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {
  success;
  validationMessage;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(f: NgForm) {
    this.success = false;
    this.validationMessage = '';
    console.log(f.value);
    const data = {
      to : f.value.to,
      from : f.value.from,
      objet : f.value.objet,
      message : f.value.message,
    };
    console.log(data);

    this.authService.AddPermission(data).subscribe(
      (data) => {
        this.success = true;
        console.log(data);
        if (data.state) {
          this.router.navigateByUrl('/permission');
          this.validationMessage = data.message;
        }else{
          this.validationMessage = data.message
        }

      }, (err) => {
        setTimeout( () => {
          this.success = true;
        }, 2000);
      }
    );
  }

}
