<app-navbar></app-navbar>

<div class="container-fluid full-page">

    <div class="row">

        <div class="col-md-1 full-container">

            <app-sidemenu></app-sidemenu>

        </div>

        <div class="col-md-11 full-container">

            <div class="container-fluid general-table-sales">
                <div class="text-center" *ngIf="loading.data === true">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-4">
                        <ul class="list-group mt-5" *ngIf="sale">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Numéro de commande
                                <span class="badge badge-pill">
                                  {{ sale[0].order.order_number }}
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Date
                                <span class="badge badge-pill">
                                  {{ sale[0].order.created_at | date:'short':'UTC':'fr' }}
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Quantité
                                <span class="badge badge-pill">
                                  {{ sale[0].order.quantity }}
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Total
                                <span class="badge badge-pill">
                                  {{ sale[0].order.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Montant payé
                                <span class="badge badge-pill">
                                  {{ sale[0].order.avance | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="sale[0].order.coupon">
                                Remise
                                <span class="badge badge-pill">
                                  {{ sale[0].order.coupon | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="!sale[0].order.coupon">
                                Remise
                                <span class="badge badge-pill text-danger">
                                  NEANT
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Reste à payer
                                <span class="badge badge-pill">
                                  {{ sale[0].order.reste | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
                                </span>
                            </li>

                        </ul>

                        <ul class="list-group mt-5" *ngIf="sale">
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Nom & Prénoms Client
                                <span class="badge badge-pill">
                                  {{ sale[0].order.first_name }}
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Email Client
                                <span class="badge badge-pill">
                                  {{ sale[0].order.email }}
                                </span>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-center">
                                Numéro de téléphone
                                <span class="badge badge-pill">
                                  {{ sale[0].order.phone }}
                                </span>
                            </li>
                        </ul>

                        <div *ngIf="typePayements">
                            <ul class="list-group mt-5" *ngFor="let typePayment of typePayements">
                                <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="typePayment.type_payement === 'especes' || typePayment.type_payement === 'echelonner'">
                                    Règlement en espèces
                                    <span class="badge badge-pill">
                                      {{ typePayment.montant }}
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="typePayment.type_payement === 'soutra'">
                                    Règlement par soutrali
                                    <span class="badge badge-pill">
                                      {{ typePayment.montant }}
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="typePayment.type_payement === 'carte'">
                                    Règlement par carte de crédit
                                    <span class="badge badge-pill">
                                      {{ typePayment.montant }}
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="typePayment.type_payement === 'cheque'">
                                    Règlement par chèque
                                    <span class="badge badge-pill">
                                      {{ typePayment.montant }}
                                    </span>
                                </li>
                                <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="typePayment.type_payement === 'mobile-money'">
                                    Règlement par mobile money
                                    <span class="badge badge-pill">
                                      {{ typePayment.montant }}
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <br><br><br>
                        <div>
                            <li class="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                commentaire
                                <span class="badge badge-pill text-wrap">
                                  {{ sale[0].order.commentaire }}
                                </span>
                            </li>
                        </div>
                        <br><br><br>
                        <button class="btn btn-outline-warning btn-block" (click)="printFacture(null)">
                          Imprimer facture (Format ticket)
                        </button>
                    </div>

                    <div class="col-md-8">

                        <ul class="nav nav-pills mt-5 mb-3 nav-justified" id="pills-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" id="home-tab-2" data-toggle="tab" href="#home-2" role="tab" aria-controls="home" aria-selected="true" (click)="changeOnglet('versement')">
                  Historiques Versements
                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" (click)="changeOnglet('product')">
                 Liste Produits
                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home-2" role="tabpanel" aria-labelledby="home-tab-2">
                                <div class="table-scrollable p-2">
                                    <table class="table table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Montants payés</th>
                                                <th scope="col">Montants restants</th>
                                            </tr>
                                        </thead>
                                        <tbody *ngIf="details">
                                            <tr *ngFor="let detail of details">
                                                <th scope="row">
                                                    Versement n° {{ detail.num + 1 }}
                                                </th>
                                                <td>
                                                    {{ detail.created_at | date:'short':'UTC':'fr' }}
                                                </td>
                                                <td>
                                                    {{ detail.montant | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
                                                </td>
                                                <td>
                                                    {{ detail.reste | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <button class="btn primary-am mt-5 btn-block" data-toggle="modal" data-target="#exampleModalScrollableS">
                    Nouveau Versement
                  </button>
                            </div>
                            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <!-- table product -->
                                <table datatable class="row-border hover mt-3" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                    <thead>
                                        <tr>
                                            <th>Nom</th>
                                            <th>Photo</th>
                                            <th>Quantité </th>
                                            <th>Prix unitaire</th>
                                            <th>Prix Total</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let product of products">
                                            <td>{{product.title }}</td>
                                            <td>
                                                <img src="{{ product.photo }}" width="45" height="45" alt="" srcset="">
                                            </td>
                                            <td>{{ product.quantity }}</td>
                                            <td>{{ product.price | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                                            <td>{{ product.amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                                            <td>
                                                <button class="btn btn-outline-danger btn-sm" data-toggle="modal" data-target="#exampleModalScrollableM" (click)="retiredProduct(product.product_id)">
                          Rétirer
                        </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!-- // table product -->
                            </div>
                        </div>

                    </div>

                </div>
            </div>


        </div>

    </div>

</div>



<!-- Modal -->
<div class="modal fade" id="exampleModalScrollableS" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form method="post" (ngSubmit)="createNewVersement()">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Création d'un versement</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex align-items-center text-warning mb-3" *ngIf="loading.create === true">
                        <strong>Création ...</strong>
                        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Entrez le montant</label>
                        <input type="number" name="newMontant" [(ngModel)]="newMontant" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                        <small id="emailHelp" class="form-text text-danger">Soyez sûr du montant inscrit.</small>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Sélectionnez le type de paiement</label>
                        <select class="custom-select" id="inputGroupSelect02" name="typeNewMontant" [(ngModel)]="typeNewMontant">
                          <option selected value="especes">Paiement Cash</option>
                          <option selected value="avoirs">Paiement par avoirs</option>
                          <option value="soutralie">Paiement Soutrali</option>
                          <option value="bonus">Paiement Bonus</option>
                          <option value="carte">Paiement par carte</option>
                          <option value="cheque">Paiement par chèque</option>
                          <option value="mobile-money">Paiement Mobile Money</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn primary-am">Enregistrer</button>
                </div>
            </div>
        </form>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalScrollableM" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <form method="post" (ngSubmit)="retiredProductFunction()">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalScrollableTitle">Motif de retrait</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="d-flex align-items-center text-warning mb-3" *ngIf="loading.create2 === true">
                        <strong>Retour en stock ...</strong>
                        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </div>

                    <div class="alert alert-warning" *ngIf="error.required">
                        Veuillez saisir l'ensemble des champs !
                    </div>
                    <div class="form-group">

                        <div class="form-group">
                            <label for="">La quantité du produit</label>
                            <input type="number" min="1" aria-multiselectable="false" name="quantity" [(ngModel)]="retrait.quantity" class="form-control" id="">
                        </div>

                        <label for="inputState">Action du retour en stock</label>
                        <select id="inputState" name="action" [(ngModel)]="retrait.action_caisse" class="form-control">
              <option selected>Choisir...</option>
              <option value="argent_retourner">Rendre son argent</option>
              <option value="avoir">Ajouter à ses avoirs</option>
            </select>
                    </div>

                    <div class="form-group">
                        <label for="inputState">Motifs de retour en stock</label>
                        <select id="inputState" name="motif" [(ngModel)]="retrait.motif" class="form-control" (change)="verifyMotif($event)">
              <option selected>Choisir...</option>
              <option value="article defectueux">Article Défectueux</option>
              <option value="changement avis">Changement d'avis</option>
              <option value="autres">Autres</option>
            </select>
                    </div>

                    <div class="form-group" *ngIf="state.inputOther">
                        <label for="">Entrez le motif</label>
                        <input type="text" class="form-control" name="otherMotif" [(ngModel)]="retrait.autre_motif">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                    <button type="submit" class="btn primary-am">Valider</button>
                </div>
            </div>
        </form>
    </div>
</div>