<app-navbar></app-navbar>

<div class="container-fluid full-page">

    <div class="row">

        <div class="col-md-1 full-container">

            <app-sidemenu></app-sidemenu>

        </div>

        <div class="text-center container">
            <div class="text-center">
                <h1>Details de permission</h1>
            </div>
            <br>
            <br>
            <br>
            <br>

            <div class="main">
                <div class="form-group">
                    <h1 for="exampleFormControlInput1">{{permission.by.name}}</h1>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Date de depart</label>
                    <input type="date" [(ngModel)]="permission.date_to" class="form-control" readonly>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Date de retour</label>
                    <input type="date" [(ngModel)]="permission.date_from" class="form-control" readonly>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlInput1">Object</label>
                    <input type="email" [(ngModel)]="permission.objet" class="form-control" id="exampleFormControlInput1" readonly>
                </div>
                <div class="form-group">
                    <label for="exampleFormControlTextarea1">Message</label>
                    <textarea class="form-control" [(ngModel)]="permission.message" id="exampleFormControlTextarea1" rows="10" cols="50" readonly></textarea>
                </div>
                <div *ngIf="user && user.role === 'admin' && user.is_manager === 1">
                    <select name="state" [(ngModel)]="permission.etat" class="form-control" id="">
                      <option value="En attente">En attente</option>
                      <option value="Validé">Validé</option>
                      <option value="Rejeté">Rejeté</option>
                    </select> <br>
                    <div></div>
                    <div class="lds-dual-ring" *ngIf="success === true"></div>
                    <button *ngIf="success === false" type="submit" class="btn btn-primary mb-2" (click)="updatePermission()">Envoyer</button>
                    <div *ngIf="message" class="alert alert-success" role="alert">
                        {{message}}
                    </div>
                </div>


            </div>
        </div>

    </div>

</div>