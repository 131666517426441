<app-navbar></app-navbar>

<div class="container-fluid full-page">

    <div class="row">

        <div class="col-md-1 full-container">

            <app-sidemenu></app-sidemenu>

        </div>

        <div class="text-center container">
            <div class="text-center">
                <h1>Demande de permission</h1>
            </div>
            <br>
            <br>
            <br>
            <br>
            <div class="main">
                <form #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Date de depart</label>
                        <input type="date" name="to" ngModel class="form-control" placeholder="">
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Date de retour</label>
                        <input type="date" ngModel name="from" class="form-control" placeholder="">
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlInput1">Object</label>
                        <input type="email" ngModel name="objet" class="form-control" id="exampleFormControlInput1" placeholder="">
                    </div>
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Message</label>
                        <textarea class="form-control" ngModel name="message" id="exampleFormControlTextarea1" rows="10" cols="50"></textarea>
                    </div>
                    <button type="submit" class="btn btn-primary mb-2">Envoyer</button>
                </form>
            </div>
        </div>

    </div>

</div>
