<mat-progress-bar *ngIf="success===false" mode="indeterminate"></mat-progress-bar>

<div class="container-fluid payment-fullcontent">
    <div class="alert alert-success" *ngIf="response_checkout.success === true" style="font-weight: 300; font-size: small;">
        Votre commande a bien été prise en compte ! <br> Vous serez rédirigé sur votre ticket et l'accueil dans quelques secondes...
    </div>
    <div class="alert alert-danger" *ngIf="response_checkout.error === true" style="font-weight: 300; font-size: small;">
        Une erreur est survenue, veuillez réessayez plus tard !
    </div>
    <div class="card-title">
        <h1>Paiement</h1>
        <hr>
    </div>
    <div class="card-customer" *ngIf="customer">
        <h1 style="color: #ff817a;">
            {{ customer.name }}

            <div class="input-group-prepend" style="float: right;" *ngIf="typePayement && typePayement === 'echelonner'">
                <!--<button class="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{ typePayementEchelonne }}</button>
            <div class="dropdown-menu">
              <a class="dropdown-item" style="cursor: pointer;" (click)="selectTypePayementToEchelonne('especes')">En espèces</a>
              <a class="dropdown-item" style="cursor: pointer;" (click)="selectTypePayementToEchelonne('carte')">Par carte</a>
              <a class="dropdown-item" style="cursor: pointer;" (click)="selectTypePayementToEchelonne('cheque')">Par chèque</a>
              <a class="dropdown-item" style="cursor: pointer;" (click)="selectTypePayementToEchelonne('mobile-money')">Par mobile money</a>
              <div role="separator" class="dropdown-divider"></div>
              <a class="dropdown-item" style="cursor: pointer;" (click)="selectTypePayementToEchelonne('soutrali')">Par soutrali</a>
            </div> -->

                <select class="custom-select" name="typePayementEchelonne" (change)="selectTypePayementToEchelonne()" [(ngModel)]="typePayementEchelonne">
              <option value="especes">En espèces</option>
              <option value="carte">Par carte</option>
              <option value="cheque">Par chèque</option>
              <option value="mobile-money">Par mobile money</option>
              <option value="soutrali">Par soutrali</option>
            </select>
            </div>

        </h1>
        <div class="card-detail-customer">
            <p class="mb-1">
                <i class="iii material-icons">
                  call
                </i> &nbsp; {{ customer.telephone }}

            </p>
            <span>
                <i class="iii material-icons">
                  mail
                </i> &nbsp;
                {{ customer.email }}
            </span>
            <br>
            <span>
              <i class="iii material-icons">
                monetization_on
              </i> &nbsp;
              {{ customer.avoirs }} Fcfa <small>(avoirs) <button class="btn btn-dark btn-sm ml-4" [disabled]="stateAvoirUse" (click)="useAvoir()"><i class="material-icons">touch_app</i></button></small>
            </span> <br>
            <span>
              <i class="iii material-icons">
                monetization_on
              </i> &nbsp;
              {{ customer.bonus }} Fcfa <small>(bonus) expire le ({{customer.bonusDate}}) <button class="btn btn-dark btn-sm ml-4" [disabled]="stateBonusUse" (click)="useBonus()"><i class="material-icons">touch_app</i></button></small>
            </span>

            <div style="float: right;">
                <div class="row p-2" style="width: 100%;">
                    <div class="col-md-3" *ngIf="jsonPayementEchelonne.mode === 'mobile-money'">
                        <span>
                  Réseau:
                </span>
                    </div>
                    <div class="col-md-6" *ngIf="jsonPayementEchelonne.mode === 'cheque'">
                        <span>
                  Compte:
                </span>
                    </div>
                    <div class="col-md-3" *ngIf="jsonPayementEchelonne.mode === 'mobile-money' && jsonPayementEchelonne.network !== ''">
                        {{ jsonPayementEchelonne.network }}
                    </div>
                    <div class="col-md-6" *ngIf="jsonPayementEchelonne.mode === 'cheque' && jsonPayementEchelonne.numAccount !== ''">
                        {{ jsonPayementEchelonne.numAccount }}
                    </div>
                    <div class="col-md-3" *ngIf="jsonPayementEchelonne.mode === 'mobile-money'">
                        <span>
                  Téléphone:
                </span>
                    </div>
                    <div class="col-md-3" *ngIf="jsonPayementEchelonne.mode === 'mobile-money' && jsonPayementEchelonne.num !== ''">
                        {{ jsonPayementEchelonne.num }}
                    </div>
                </div>
            </div>
            <!--<div class="float-right">
              <i class="iii material-icons">

              </i>
              <span>
                Avoirs :  {{ customer.avoirs }} Fcfa
              </span>
              <button class="btn btn-dark btn-sm">
                Utiliser
              </button>
            </div> -->
        </div>
    </div>

    <div class="card-payment">

        <div class="row">

            <div class="col-md-2">
                <!-- List group -->
                <div class="list-group" id="myList" role="tablist">
                    <a class="list-group-item list-group-item-action active" data-toggle="list" href="#main" (click)="setTypePayement('especes')" role="tab">Paiement Cash</a>
                    <a class="list-group-item list-group-item-action" data-toggle="list" href="#house" (click)="setTypePayement('echelonner')" role="tab">Paiement Echelloner</a>
                    <a class="list-group-item list-group-item-action" data-toggle="list" href="#profile" (click)="setTypePayement('soutralie')" role="tab">Paiement Soutrali</a>
                    <a class="list-group-item list-group-item-action" data-toggle="list" href="#messages" (click)="setTypePayement('card/check')" role="tab">Paiement Par Carte / Chèque</a>
                    <a class="list-group-item list-group-item-action" data-toggle="list" href="#settings" (click)="setTypePayement('mobile-money')" role="tab">Paiement Mobile Money</a>
                </div>

            </div>

            <div class="col-md-10">
                <!-- Content group -->
                <div class="tab-content">
                    <div class="tab-pane active" id="main" role="tabpanel" *ngIf="typePayement && typePayement === 'especes'">
                        <div align="center" *ngIf="error_in_money === true">
                            <small class="err_to_cash">
                          La somme reçu du client est inférieur à celle du total. S'il s'agit d'un paiement échelloné, veuillez migrer sur l'onglet approprié !
                        </small>
                        </div>
                        <span>Paiement Espèce simple</span>

                        <div class="row cash">
                            <div class="col-md-6 data-payment">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <p class="price" *ngIf="!Total">0 Fcfa</p>
                                        <p class="price" *ngIf="Total">{{ Total | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
                                        <small class="desc">Total</small>
                                    </li>
                                    <li class="list-group-item">
                                        <p class="price" *ngIf="SumCustomer">{{ SumCustomer | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
                                        <input type="text" [(ngModel)]="SumCustomer" class="form-control">
                                        <p class="price" *ngIf="!SumCustomer">0 Fcfa</p>
                                        <small class="desc">Reçu du client</small>
                                    </li>
                                    <li class="list-group-item">
                                        <p class="price" *ngIf="exchange">{{ exchange | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
                                        <p class="price" *ngIf="!exchange">0 Fcfa</p>
                                        <small class="desc">Echange (monnaie)</small>
                                    </li>
                                    <li class="list-group-item">
                                        <small class="desc">Commentaire</small>
                                        <textarea [(ngModel)]="commentaire" class="form-control" id="" cols="30" rows="5" required></textarea>
                                    </li>
                                </ul>
                                <div class="row supp bord" align="center">
                                    <div class="col-md-6">
                                        <div class="form-group" *ngIf="visibility.option === true">
                                            <label for="exampleFormControlSelect1">Choisir l'option de réduction</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" [(ngModel)]="reduction.option" value="global" (change)="setType($event)">
                                                <label class="form-check-label" for="inlineRadio1">Réduction globale</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" data-toggle="modal" data-target="#exampleModal" type="radio" name="inlineRadioOptions" [(ngModel)]="reduction.option" id="inlineRadio2" value="byProduct" (change)="setType($event)">
                                                <label class="form-check-label" for="inlineRadio2">Réduction par produit</label>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="visibility.typeReduction === 'true'">
                                            <label for="exampleFormControlSelect1">Choisir le type de réduction</label>
                                            <select class="form-control" id="exampleFormControlSelect1" name="typeReduction" [(ngModel)]="reduction.type" (change)="setValueReduction($event)">
                                            <option value="percent">Par pourcentage</option>
                                            <option value="fixed">Par prix fixe</option>
                                          </select>
                                        </div>
                                        <div class="form-group" *ngIf="visibility.valueReduction === 'true'">
                                            <label for="exampleFormControlSelect1">Entrez la valeur</label>
                                            <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="valueReduction" [(ngModel)]="reduction.value">
                                        </div>
                                        <button class="btn primary-am" (click)="requestReduction()">
                                          Appliquer réduction
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group" *ngIf="visibility.sommeDelivery === 'true'">
                                            <label for="exampleFormControlSelect1">Entrez la somme</label>
                                            <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="deliveryPrice" [(ngModel)]="delivery.value">
                                        </div>
                                        <div class="form-group" *ngIf="visibility.sommeDelivery === 'true'">
                                            <label for="exampleFormControlSelect1">Entrez l'adresse de livraison</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="deliveryPlace" [(ngModel)]="delivery.adresse">
                                        </div>
                                        <button class="btn primary-am" (click)="setSumDelivery()">
                                          Coût de livraison
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 calculator" align="center">
                                <div class="row plaque">
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="1">1</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="2">2</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="3">3</button>
                                    </div>
                                </div>
                                <div class="row plaque">
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="4">4</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="5">5</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="6">6</button>
                                    </div>
                                </div>
                                <div class="row plaque">
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="7">7</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="8">8</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="9">9</button>
                                    </div>
                                </div>
                                <div class="row plaque">
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id=".">.</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="0">0</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="eff">C</button>
                                    </div>
                                </div>

                                <button class="btn btn-lg text-light mt-5" style="width: 60%;background-color: black;" (click)="checkout()">Confirmer</button>

                            </div>
                        </div>

                    </div>
                    <!--delimited-->
                    <div class="tab-pane" id="house" role="tabpanel" *ngIf="typePayement && typePayement === 'echelonner'">
                        <span>Paiement échelloné</span>
                        <div class="row cash">

                            <div class="col-md-6 data-payment">
                                <ul class="list-group list-group-flush">
                                    <li class="list-group-item">
                                        <p class="price" *ngIf="!Total">0 Fcfa</p>
                                        <p class="price" *ngIf="Total">{{ Total | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
                                        <small class="desc">Total</small>
                                    </li>
                                    <li class="list-group-item">
                                        <p class="price" *ngIf="SumCustomer">{{ SumCustomer | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
                                        <input type="text" [(ngModel)]="SumCustomer" class="form-control">
                                        <p class="price" *ngIf="!SumCustomer">0 Fcfa</p>
                                        <small class="desc">Reçu du client</small>
                                    </li>
                                    <li class="list-group-item">
                                        <p class="price" *ngIf="exchange">{{ exchange | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
                                        <p class="price" *ngIf="!exchange">0 Fcfa</p>
                                        <small class="desc">Echange (monnaie)</small>
                                    </li>
                                    <li class="list-group-item">
                                        <small class="desc">Commentaire</small>
                                        <textarea [(ngModel)]="commentaire" class="form-control" id="" cols="30" rows="5" required></textarea>
                                    </li>
                                </ul>
                                <div class="row supp bord" align="center">
                                    <div class="col-md-6">
                                        <div class="form-group" *ngIf="visibility.option === true">
                                            <label for="exampleFormControlSelect1">Choisir l'option de réduction</label>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" [(ngModel)]="reduction.option" value="global" (change)="setType($event)">
                                                <label class="form-check-label" for="inlineRadio1">Réduction globale</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" data-toggle="modal" data-target="#exampleModal" type="radio" name="inlineRadioOptions" [(ngModel)]="reduction.option" id="inlineRadio2" value="byProduct" (change)="setType($event)">
                                                <label class="form-check-label" for="inlineRadio2">Réduction par produit</label>
                                            </div>
                                        </div>
                                        <div class="form-group" *ngIf="visibility.typeReduction === 'true'">
                                            <label for="exampleFormControlSelect1">Choisir le type de réduction</label>
                                            <select class="form-control" id="exampleFormControlSelect1" name="typeReduction" [(ngModel)]="reduction.type" (change)="setValueReduction($event)">
                                            <option value="percent">Par pourcentage</option>
                                            <option value="fixed">Par prix fixe</option>
                                          </select>
                                        </div>
                                        <div class="form-group" *ngIf="visibility.valueReduction === 'true'">
                                            <label for="exampleFormControlSelect1">Entrez la valeur</label>
                                            <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="valueReduction" [(ngModel)]="reduction.value">
                                        </div>
                                        <button class="btn primary-am" (click)="requestReduction()">
                                          Appliquer réduction
                                        </button>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group" *ngIf="visibility.sommeDelivery === 'true'">
                                            <label for="exampleFormControlSelect1">Entrez la somme</label>
                                            <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="deliveryPrice" [(ngModel)]="delivery.value">
                                        </div>
                                        <div class="form-group" *ngIf="visibility.sommeDelivery === 'true'">
                                            <label for="exampleFormControlSelect1">Entrez l'adresse de livraison</label>
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="deliveryPlace" [(ngModel)]="delivery.adresse">
                                        </div>
                                        <button class="btn primary-am" (click)="setSumDelivery()">
                                          Coût de livraison
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 calculator" align="center">
                                <div class="row plaque">
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="1">1</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="2">2</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="3">3</button>
                                    </div>
                                </div>
                                <div class="row plaque">
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="4">4</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="5">5</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="6">6</button>
                                    </div>
                                </div>
                                <div class="row plaque">
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="7">7</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="8">8</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="9">9</button>
                                    </div>
                                </div>
                                <div class="row plaque">
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id=".">.</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="0">0</button>
                                    </div>
                                    <div class="col-md-4">
                                        <button class="btn btn-secondary btn-block cal" data-id="eff">C</button>
                                    </div>
                                </div>

                                <button class="btn btn-lg text-light mt-5" style="width: 60%;background-color: black;" (click)="checkoutEchelonne()">Confirmer</button>

                            </div>
                        </div>

                    </div>
                    <!--// delimited-->
                    <div class="tab-pane" id="profile" role="tabpanel" *ngIf="typePayement && typePayement === 'soutralie'">
                        <div class="row soutrali">
                            <div class="col-md-6">
                                <div id="info-soutra">
                                    <h1>Mon compte</h1>
                                    <p *ngIf="customer.noCompte !== null">
                                        Numéro de compte : {{ customer.noCompte }}
                                    </p>
                                    <p *ngIf="customer.noCompte === null">
                                        Numéro de compte : NEANT
                                    </p>
                                    <p *ngIf="customer.solde !== null">
                                        Solde : {{ customer.solde | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
                                    </p>
                                    <p *ngIf="customer.solde === null">
                                        Solde : 0 Fcfa
                                    </p>
                                </div>
                            </div>
                            <li class="list-group-item">
                                <small class="desc">Commentaire</small>
                                <textarea [(ngModel)]="commentaire" class="form-control" id="" cols="30" rows="5" required></textarea>
                            </li>
                            <div class="col-md-6">
                                <button class="btn primary-am btn-lg btn-soutra" (click)="useSoutra()">
                                  Déclancher
                                </button>
                            </div>
                            <div class="col-md-6">
                                <button *ngIf="soutraBtn === true" class="btn primary-am btn-lg btn-soutra" (click)="checkoutEchelonne()">
                                Confirmer
                              </button>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane" id="messages" role="tabpanel" *ngIf="typePayement && (typePayement === 'card/check' || typePayement === 'carte' || typePayement === 'cheque')">
                        <div class="visa">
                            <div class="card" align="center">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p>Total à payer</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="price" *ngIf="!Total">0 Fcfa</p>
                                            <p class="price" *ngIf="Total">{{ Total | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Choisir l'option</label>
                                        <select class="form-control" id="exampleFormControlSelect1" name="optionPayment" (change)="CardOrCheque($event)" required>
                                          <option value="">Choisir une option</option>
                                          <option value="carte">Carte Bancaire</option>
                                          <option value="cheque">Chèque</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" *ngIf="check.state === true">
                                        <label for="exampleInputEmail1">Le numéro du chèque</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="" name="numAccount" [(ngModel)]="check.numAccount">
                                    </div>
                                </div>
                            </div>
                            <li class="list-group-item">
                                <small class="desc">Commentaire</small>
                                <textarea [(ngModel)]="commentaire" class="form-control" id="" cols="30" rows="5" required></textarea>
                            </li>
                            <div class="row supp bord" align="center">
                                <div class="col-md-6">
                                    <div class="form-group" *ngIf="visibility.option === true">
                                        <label for="exampleFormControlSelect1">Choisir l'option de réduction</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" [(ngModel)]="reduction.option" value="global" (change)="setType($event)">
                                            <label class="form-check-label" for="inlineRadio1">Réduction globale</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" data-toggle="modal" data-target="#exampleModal" type="radio" name="inlineRadioOptions" [(ngModel)]="reduction.option" id="inlineRadio2" value="byProduct" (change)="setType($event)">
                                            <label class="form-check-label" for="inlineRadio2">Réduction par produit</label>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="visibility.typeReduction === 'true'">
                                        <label for="exampleFormControlSelect1">Choisir le type de réduction</label>
                                        <select class="form-control" id="exampleFormControlSelect1" name="typeReduction" [(ngModel)]="reduction.type" (change)="setValueReduction($event)">
                                      <option value="percent">Par pourcentage</option>
                                      <option value="fixed">Par prix fixe</option>
                                    </select>
                                    </div>
                                    <div class="form-group" *ngIf="visibility.valueReduction === 'true'">
                                        <label for="exampleFormControlSelect1">Entrez la valeur</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="valueReduction" [(ngModel)]="reduction.value">
                                    </div>
                                    <button class="btn primary-am" (click)="requestReduction()">
                                    Appliquer réduction
                                  </button>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" *ngIf="visibility.sommeDelivery === 'true'">
                                        <label for="exampleFormControlSelect1">Entrez la somme</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="deliveryPrice" [(ngModel)]="delivery.value">
                                    </div>
                                    <div class="form-group" *ngIf="visibility.sommeDelivery === 'true'">
                                        <label for="exampleFormControlSelect1">Entrez l'adresse de livraison</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="deliveryPlace" [(ngModel)]="delivery.adresse">
                                    </div>
                                    <button class="btn primary-am" (click)="setSumDelivery()">
                                    Coût de livraison
                                  </button>
                                </div>
                            </div>
                            <button class="btn btn-lg mt-5" type="submit" style="width: 100%;color: white;background-color: black;" (click)="checkoutCreditCard()">Confirmer</button>
                        </div>
                    </div>
                    <div class="tab-pane" id="settings" role="tabpanel" *ngIf="typePayement && typePayement === 'mobile-money'">

                        <div class="visa">
                            <div class="card" align="center">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <p>Total à payer</p>
                                        </div>
                                        <div class="col-md-6">
                                            <p class="price" *ngIf="!Total">0 Fcfa</p>
                                            <p class="price" *ngIf="Total">{{ Total | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleFormControlSelect1">Choisir le service de mobile money</label>
                                        <select class="form-control" id="exampleFormControlSelect1" name="network" [(ngModel)]="mobileMoney.network">
                                          <option value="">Choisissez votre réseau</option>
                                          <option value="mtn">MTN Money</option>
                                          <option value="orange">Orange Money</option>
                                          <option value="moov">Moov Money</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Numéro du client</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entrer le numéro du client" value="+225 " name="num" [(ngModel)]="mobileMoney.num">
                                    </div>
                                </div>
                                <!--<div class="col-md-4">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Somme Reçu du client</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entrer la somme reçue">
                                    </div>
                                  </div>-->
                            </div>
                            <li class="list-group-item">
                                <small class="desc">Commentaire</small>
                                <textarea [(ngModel)]="commentaire" class="form-control" id="" cols="30" rows="5" required></textarea>
                            </li>
                            <div class="row supp bord" align="center">
                                <div class="col-md-6">
                                    <div class="form-group" *ngIf="visibility.option === true">
                                        <label for="exampleFormControlSelect1">Choisir l'option de réduction</label>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" [(ngModel)]="reduction.option" value="global" (change)="setType($event)">
                                            <label class="form-check-label" for="inlineRadio1">Réduction globale</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" data-toggle="modal" data-target="#exampleModal" type="radio" name="inlineRadioOptions" [(ngModel)]="reduction.option" id="inlineRadio2" value="byProduct" (change)="setType($event)">
                                            <label class="form-check-label" for="inlineRadio2">Réduction par produit</label>
                                        </div>
                                    </div>
                                    <div class="form-group" *ngIf="visibility.typeReduction === 'true'">
                                        <label for="exampleFormControlSelect1">Choisir le type de réduction</label>
                                        <select class="form-control" id="exampleFormControlSelect1" name="typeReduction" [(ngModel)]="reduction.type" (change)="setValueReduction($event)">
                                      <option value="percent">Par pourcentage</option>
                                      <option value="fixed">Par prix fixe</option>
                                    </select>
                                    </div>
                                    <div class="form-group" *ngIf="visibility.valueReduction === 'true'">
                                        <label for="exampleFormControlSelect1">Entrez la valeur</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="valueReduction" [(ngModel)]="reduction.value">
                                    </div>
                                    <button class="btn primary-am" (click)="requestReduction()">
                                    Appliquer réduction
                                  </button>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" *ngIf="visibility.sommeDelivery === 'true'">
                                        <label for="exampleFormControlSelect1">Entrez la somme</label>
                                        <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="deliveryPrice" [(ngModel)]="delivery.value">
                                    </div>
                                    <div class="form-group" *ngIf="visibility.sommeDelivery === 'true'">
                                        <label for="exampleFormControlSelect1">Entrez l'adresse de livraison</label>
                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" name="deliveryPlace" [(ngModel)]="delivery.adresse">
                                    </div>
                                    <button class="btn primary-am" (click)="setSumDelivery()">
                                    Coût de livraison
                                  </button>
                                </div>
                            </div>
                            <button class="btn text-light btn-lg mt-5" style="width: 100%;background-color: black;" (click)="checkoutMobileMoney()">Confirmer</button>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Liste des produits</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body" *ngIf="productToCart">
                <div class="alert alert-warning" *ngIf="stateErrorInReductionSp">
                    Si vous voulez appliquez appliquer une réduction sur le produit, veuillez spécifiez les montants !
                </div>
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col">Nom</th>
                            <th scope="col">Photo</th>
                            <th scope="col">Prix Unitaire</th>
                            <th scope="col">Réduction Fixe</th>
                            <th scope="col">Réduction Pourcentage</th>
                            <th scope="col">Action</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of productToCart">
                            <th scope="row">{{ product.name }}</th>
                            <th align="center">
                                <img class="img-cart" [src]=product.img width="60" height="60" alt="">
                            </th>
                            <td>{{ product.price }}</td>
                            <td>
                                <input type="number" min="0" class="form-control" name="reduction" [id]=product.identify id="">
                            </td>
                            <td>
                                <input type="number" min="0" max="100" class="form-control" name="reduction" id="pourc-{{ product.identify }}" id="">
                            </td>
                            <td>
                                <button class="btn btn-info btn-sm" (click)="setReductionToProduct(product.identify, product.price, product.quantity)">Appliquer</button>
                            </td>
                            <td>
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status" id="loader-{{ product.identify }}" style="display: none;">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="applyReductionToTotal()">OK</button>
            </div>
        </div>
    </div>
</div>