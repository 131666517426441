
<div class="container-fluid">

  <div class="row">

    <div class="col-md-4 full-login img-container">

      <img src="https://image.freepik.com/free-photo/smiley-business-woman-working-cashier_23-2148366564.jpg" alt="" width="100%" height="100%">

    </div>

    <div class="col-md-8 full-login">
      <mat-progress-bar *ngIf="success==false"  mode="indeterminate"></mat-progress-bar>

        <div id="form-container" align="center">

          <h1 class="title">Bienvenue à la caisse</h1>
          <p class="subtitle">Ouvrir ma caisse</p>
          <small class="text-danger" *ngIf="errors.error_global !== ''" >{{ errors.error_global }}</small>

          <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Adresse E-mail</label>
              <input type="email" class="form-control" name="email" [(ngModel)]="data.email" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Entrez l'adresse e-mail">
              <small class="text-danger" *ngIf="errors.error_email !== ''" >{{ errors.error_email }}</small>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Mot de passe</label>
              <input type="password" class="form-control" name="password" [(ngModel)]="data.password" id="exampleInputPassword1" placeholder="Entrez le mot de passe">
              <small class="text-danger" *ngIf="errors.error_password !== ''" >{{ errors.error_password }}</small>
            </div>
            <br>
            <button type="submit" (click)="login()" class="btn btn-primary">Se connecter</button>
          </form>

        </div>

        <p class="word-foot">Production By LCE</p>

    </div>

  </div>

</div>
