import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-permission-detail',
  templateUrl: './permission-detail.component.html',
  styleUrls: ['./permission-detail.component.css']
})
export class PermissionDetailComponent implements OnInit {
  id;
  user;
  success;
  message;
  permission;
  constructor(private authService: AuthService,private router: Router,private route: ActivatedRoute) {  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (params => {
        this.id = params.get('id');
      })
    );
    this.user = JSON.parse(localStorage.getItem('caissier'));
    this.onSubmit()
  }

  onSubmit() {
    this.success = true;
    this.authService.GetPermissionDetail(this.id).subscribe(
      (data) => {
        this.success = false;
        this.permission = data.data
        console.log(data);
      }, (err) => {
        this.success = false;
      }
    );
  }
  updatePermission(){
    this.success = true;
    this.authService.PutPermissionDetail(this.id,this.permission).subscribe(
      (data) => {
        this.success = false;
        this.router.navigateByUrl('/permission-detail/' + this.id)
        this.message = data.message
      }, (err) => {
        this.success = false;
      }
    );
  }

}
