<div class="container-fluid general-table-sales">
    <div class="text-center" *ngIf="visibility === true">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" *ngIf="user">
        <thead>
            <tr>
                <th *ngIf="user.role === 'admin'">Caissier</th>
                <th>NUM COMMANDE</th>
                <th>Date </th>
                <th>Nom & Prénoms Client</th>
                <th>Numéro Client</th>
                <th>Type Client</th>
                <th>Quantité Totale Commandée</th>
                <th>Sous-total</th>
                <th>Total</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sale of sales">
                <td *ngIf="user.role === 'admin' && sale.caissier !== null ">{{ sale.caissier.name}}</td>
                <td *ngIf="user.role === 'admin' && sale.caissier === null " class="text-danger">NEANT</td>
                <td>{{sale.order.order_number }}</td>
                <td>{{ sale.order.created_at | date:'short':'UTC':'fr' }}</td>
                <td *ngIf="sale.client !== null">{{ sale.client.name }}</td>
                <td *ngIf="sale.client === null"> {{sale.order.first_name}} </td>
                <td *ngIf="sale.client !== null">{{ sale.client.telephone }}</td>
                <td *ngIf="sale.client === null">{{sale.order.phone}} </td>
                <td *ngIf="sale.client !== null">{{ sale.client.type }}</td>
                <td *ngIf="sale.client === null"> </td>
                <td>{{ sale.order.quantity }}</td>
                <td>{{sale.order.sub_total | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
                <td>{{ sale.order.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
            </tr>
        </tbody>
    </table>
</div>