<div class="container-fluid table-orders">

  <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
    <thead>
      <tr>
        <th>NUM COMMANDE</th>
        <th>Nom & Prénoms Client</th>
        <th>E-mail </th>
        <th>Numéro Client</th>
        <th>Quantité Totale Commandée</th>
        <th>Sous-total</th>
        <th>Total</th>
        <th>Date</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orders">
        <td>{{ order.order_number }}</td>
        <td>{{ order.first_name }}</td>
        <td>{{ order.email }}</td>
        <td>{{ order.phone }}</td>
        <td>{{ order.quantity }}</td>
        <td>{{ order.sub_total | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
        <td>{{ order.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
        <td>{{ order.created_at | date:'short':'UTC':'fr' }}</td>
        <td>
          <button class="btn btn-warning text-light" (click)='goToDetailOrder(order.id)'>
            Voir Détails
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <div class="text-center" *ngIf="visibility === true">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
