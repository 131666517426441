<div class="container-fluid p-2">
  <div class="text-center" *ngIf="visibility === true">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4" *ngIf="orderDetail">
      <div class="card mb-3" style="width: 100%;">
        <div class="card-body">
          <h5 class="card-title">Informations Commandes</h5>
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Numéro Commande
              <span class="badge badge-pill">
                {{ orderDetail.order.order_number }}
              </span>
            </li>

            <li class="list-group-item d-flex justify-content-between align-items-center">
              Quantité
              <span class="badge badge-pill">
                {{ orderDetail.order.quantity }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Sous-Total
              <span class="badge badge-pill">
                {{ orderDetail.order.sub_total | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Total
              <span class="badge badge-pill">
                {{ orderDetail.order.total_amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Date
              <span class="badge badge-pill">
                {{ orderDetail.order.created_at | date:'short':'UTC':'fr'}}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div class="card" style="width: 100%;">
        <div class="card-body">
          <h5 class="card-title">Informations Client</h5>
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Nom Client
              <span class="badge badge-pill">
                {{ orderDetail.client.name }}
              </span>
            </li>

            <li class="list-group-item d-flex justify-content-between align-items-center">
              Email Client
              <span class="badge badge-pill">
                {{ orderDetail.client.email }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Téléphone Client
              <span class="badge badge-pill">
                {{ orderDetail.client.telephone }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Type Client
              <span class="badge badge-pill">
                {{ orderDetail.client.type }}
              </span>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center">
              Date d'anniversaire
              <span class="badge badge-pill">
                {{ orderDetail.client.birthday }}
              </span>
            </li>
          </ul>

        </div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="col-md-12">
        <button class="btn btn-danger btn-sm m-2" style="float: right;" (click)="DisableOrderToCart()">
          Annuler commande
        </button>
        <button class="btn btn-info btn-sm m-2" style="float: right;" (click)="AssingOrderToCart()">
          Passer à la caisse
        </button>
      </div>
      <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" >
        <thead>
          <tr>
            <th>Nom Produit</th>
            <th>Prix unitaire</th>
            <th>Quantité </th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody *ngIf="orderDetail">
          <tr *ngFor="let cart of orderDetail.carts">
            <td>{{ cart.article.title }}</td>
            <td>{{ cart.cart.price | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
            <td>{{ cart.cart.quantity }}</td>
            <td>{{ cart.cart.amount | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</td>
            <td>
              <button class="btn btn-sm btn-outline-danger" (click)="retiredProduct(cart.article.id)" data-toggle="modal" data-target="#exampleModalOrder">
                <i class="small material-icons" style="font-size: 16px;">
                  delete
                </i>
                Retirer
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalOrder" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <form method="post">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Informations supplémentaires</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex align-items-center text-warning mb-3" *ngIf="state === true">
            <strong>Retour en stock ...</strong>
            <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
          </div>
          <div class="alert alert-danger" *ngIf="error.state === true">
            {{ error.message }}
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Entrez la quantité</label>
            <input type="number" name="quantity" [(ngModel)]="cancel.quantity" class="form-control" id="exampleInputQuantity" required>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
          <button type="button" class="btn btn-primary" (click)="DoRetiredProduct()">Valider</button>
        </div>
      </div>
    </form>
  </div>
</div>