

<div class="alert alert-info mt-3" *ngIf="started_message && started_message === true">
  Vous pouvez maintenant utiliser la doucheuse !
</div>

<div class="alert alert-warning mt-3" *ngIf="state">
  Ce produit n'existe pas dans notre boutique
</div>

<div class="container douch-style">
  <div class="form-group" align="center">
    <label for="exampleInputEmail1" *ngIf="started_message === false">Veuillez apprêter la doucheuse ... </label>
    <input type="text" class="form-control" id="scannerInput" name="code" [(ngModel)]="code" (change)="loadCode($event)">
  </div>
</div>

<div class="container not-know" align="center">
  <button class="btn primary-am" (click)="reloadInput()">
    <i class="material-icons">
      loop
    </i>
  </button>
</div>
