
export const environment = {
  production: true,
  endPoint: 'https://accessoiresmodes.com/api/',
  url : 'https://accessoiresmodes.com/api/caisse/',
  secret_key: 'MA_SUPER_CLE_SECRETE'
};

//  export const environment = {
//   production: true,
//   endPoint: 'http://127.0.0.1:8008/api/',
//   url : 'http://127.0.0.1:8008/api/caisse/',
//   secret_key: 'MA_SUPER_CLE_SECRETE'
// };
