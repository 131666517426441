<app-navbar></app-navbar>

<div class="container-fluid full-page">

    <div class="row">

        <div class="col-md-1 full-container">

            <app-sidemenu></app-sidemenu>

        </div>

        <div class="col-md-11 full-container">

            <router-outlet name="child3"></router-outlet>

        </div>

    </div>

</div>
