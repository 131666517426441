import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-sms-create',
  templateUrl: './sms-create.component.html',
  styleUrls: ['./sms-create.component.css']
})
export class SmsCreateComponent implements OnInit {
  success;
  validationMessage;
  messagePerso;
  messageLength;
  messageNumber;
  userCount;
  smsCount;
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.messagePerso= ''
    this.messageNumber=1
    this.messageLength=0;
    this.success = false;
    this.getUser()
    this.getSmsCount()
  }
  getUser() {
    this.authService.GetUser().subscribe(
      (data) => {
        this.userCount= data
      }, (err) => {
      }
    );
  }
  getSmsCount() {
    this.authService.GetSmsCount().subscribe(
      (data) => {
        console.log('smscount',data)
        this.smsCount= data.data[0].availableUnits
        console.log('result',this.smsCount)
      }, (err) => {
      }
    );
  }
  onSubmit(f: NgForm) {
    this.success = true;
    this.validationMessage = '';
    console.log(f.value);
    const data = {
      objet : f.value.objet,
      message : f.value.message,
    };
    console.log(data);

    this.authService.AddSms(data).subscribe(
      (data) => {
        this.success = false;
        console.log(data);
        if (data.state) {
          this.router.navigateByUrl('/sms');
          this.validationMessage = data.message;
        }else{
          this.validationMessage = data.message
        }

      }, (err) => {
        setTimeout( () => {
          this.success = false;
        }, 2000);
      }
    );
  }
  personBody() {
    if (this.messagePerso !== null && this.messagePerso !== "") {
      this.messagePerso = this.messagePerso;
      this.messageLength = this.messagePerso.length;
      this.messageNumber = Math.ceil(this.messageLength / 160);
    }else{
      this.messagePerso = null;
      this.messageLength = 0;
    }
  }
  metaData(data) {
    console.log(data)
      this.messagePerso = this.messagePerso+data

  }
}
