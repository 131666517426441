<app-navbar></app-navbar>

<div class="container-fluid row">
    <div class="col-md-1 full-container">
        <app-sidemenu></app-sidemenu>
    </div>
    <div class=" container">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Détail</button>
            </li>
            <li class="nav-item" role="presentation">
                <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Utilisateur</button>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="text-center container">
                    <div class="text-center">
                        <h1>Details de permission</h1>
                    </div>
                    <br>
                    <br>
                    <br>
                    <br>
                    <div class="main">
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Object</label>
                            <input type="email" [(ngModel)]="permission.objet" class="form-control" id="exampleFormControlInput1" readonly>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlInput1">Taux de succès</label>
                            <input type="email" [(ngModel)]="permission.pourcentage" class="form-control" id="exampleFormControlInput1" readonly>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">Message</label>
                            <textarea class="form-control" [(ngModel)]="permission.message" id="exampleFormControlTextarea1" rows="10" cols="50" readonly></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="container-fluid general-table-sales">
                    <div class="text-center" *ngIf="visibility === true">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> <br><br>
                    <table datatable class="row-border hover col-md-12" [dtOptions]="dtOptions">
                        <thead>
                            <tr>
                                <th>Nom et prenoms</th>
                                <th>Telephone</th>
                                <th>Etat</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let sale of permission.users">
                                <td>{{ sale.name }}</td>
                                <td>{{ sale.telephone }}</td>
                                <td *ngIf="!sale.pivot.etat">
                                    <button class="btn btn-outline-info btn-sm">
                                      renvoyer
                                  </button>
                                </td>
                                <td *ngIf="sale.pivot.etat">
                                    <!-- <button class="btn btn-outline-info btn-sm"> -->
                                    envoyé
                                    <!-- </button> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

</div>