import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { SalesOperateService } from '../services/sales-operate.service';
@Component({
  selector: 'app-permissionlist',
  templateUrl: './permissionlist.component.html',
  styleUrls: ['./permissionlist.component.css']
})
export class PermissionlistComponent implements OnInit {
  permissions;
  visibility;
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: any = {};
  dtTrigger2: Subject<any> = new Subject<any>();
  dtOptions2: any = {};
  constructor(private salesService: SalesOperateService,private router: Router) { }

  ngOnInit(): void {
    this.dtOptions = {
      ordering: false
    };
    this.dtOptions2 = {
      ordering: false
    };
    this.getPermission()
  }

  getPermission() {
    this.visibility = true
    this.salesService.GetPermission().subscribe(
      (data) => {
        this.visibility = false
        this.permissions = data.data;
        this.dtTrigger.next();
      }, (err) => {
        console.log(err);
        this.visibility = false
      }
    );
  }
  goToDetail(idOrder) {
    /*this.historyVersement = [];
    let i = 0;
    data.forEach(element => {
      element.num = i;
      this.historyVersement.push(element);
      i++;
    });
    console.log(this.historyVersement);*/
    console.log(idOrder);
    this.router.navigateByUrl('/permission-detail/' + idOrder);
  }
  goToCreatePermission() {
    /*this.historyVersement = [];
    let i = 0;
    data.forEach(element => {
      element.num = i;
      this.historyVersement.push(element);
      i++;
    });
    console.log(this.historyVersement);*/
    this.router.navigateByUrl('/permission-create');
  }


}
