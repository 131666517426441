<app-navbar></app-navbar>

<div class="container-fluid full-page">

    <div class="row">

        <div class="col-md-1 full-container">

            <app-sidemenu></app-sidemenu>

        </div>

        <div class="col-md-11 full-container"> <br><br><br>
            <button class="btn btn-outline-info btn-sm float-right" (click)="goToCreatePermission()">
              Demander une permission
            </button> <br><br><br>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                    <div class="container-fluid general-table-sales">
                        <div class="text-center" *ngIf="visibility === true">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <table datatable class="row-border hover" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th>Demander par</th>
                                    <th>Date de demande</th>
                                    <th>Date de depart </th>
                                    <th>Date retour</th>
                                    <th>Objet</th>
                                    <th>Etat</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let sale of permissions">
                                    <td>{{sale.by.name }}</td>
                                    <td>{{sale.created_at }}</td>
                                    <td>{{ sale.date_to | date:'short':'UTC':'fr' }}</td>
                                    <td>{{ sale.date_from | date:'short':'UTC':'fr' }}</td>
                                    <td>{{ sale.objet }}</td>
                                    <td>{{ sale.etat }}</td>
                                    <td>
                                        <button class="btn btn-outline-info btn-sm" (click)="goToDetail(sale.id)">
                                          Détails
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br><br>
                </div>
            </div>

        </div>

    </div>

</div>
