<div class="row p-0 mt-2">
    <form class="form-inline my-2 my-lg-0" style="width: 90%;">
        <input class="form-control mr-sm-2 ml-5" type="search" placeholder="Recherche ..." aria-label="Search" style="width: 70%;" name="search" [(ngModel)]="search" (keyup)="OnResearch($event)">
        <button class="btn my-2 my-sm-0" style="color: black;border-color: black;" (click)="goToScanner()" type="submit">Scan code</button>
    </form>
</div>

<div class="row articles-content">

    <small *ngIf="articles && articles.length === 0">
    Aucun résultat correspond à votre recherche !
  </small>

    <div class="col-md-3 article " align="center" *ngFor="let article of articles">
        <div *ngIf="article.stock !== 0" style="cursor: pointer;" (click)="SelectProduct(article)">
            <span class="badge badge-primary" style="float: right;">
          Stock : {{ article.stock }}
        </span>
            <div class="img-content">
                <img *ngIf="article.photo !== null" src="{{ article.photo }}" alt="" width="100%" height="200px">
                <img *ngIf="article.photo === null" src="https://image.flaticon.com/icons/png/512/489/489423.png" alt="" width="100%" height="200px">
            </div>
            <h3 class="name-product">{{ article.title }}</h3>
            <p>{{ article.price | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
        </div>
        <div *ngIf="article.stock === 0" style="background-color: #dddddd; opacity: 0.75;">
            <span class="badge badge-danger" style="float: right;">
          Stock : {{ article.stock }}
        </span>
            <div class="img-content">
                <img *ngIf="article.photo !== null" src="{{ article.photo }}" alt="" width="100%" height="200px">
                <img *ngIf="article.photo === null" src="https://image.flaticon.com/icons/png/512/489/489423.png" alt="" width="100%" height="200px">
            </div>
            <h3 class="name-product">{{ article.title }}</h3>
            <p>{{ article.price | currency:'Fcfa':'symbol':'4.2-2':'fr' }}</p>
        </div>
    </div>

    <!--<div class="col-md-3 article " align="center">
      <div class="img-content">
        <img src="https://image.freepik.com/free-psd/paper-bag-mockup_35913-1727.jpg" alt="" width="100%" height="100%">
      </div>
      <h3 class="name-product">Sac en papier</h3>
      <p>5 000 Fcfa</p>

    </div>

    <div class="col-md-3 article " align="center">
      <div class="img-content">
        <img src="https://image.freepik.com/free-psd/paper-bag-mockup_35913-1727.jpg" alt="" width="100%" height="100%">
      </div>
      <h3 class="name-product">Sac en papier</h3>
      <p>5 000 Fcfa</p>

    </div>

    <div class="col-md-3 article " align="center">
      <div class="img-content">
        <img src="https://image.freepik.com/free-psd/paper-bag-mockup_35913-1727.jpg" alt="" width="100%" height="100%">
      </div>
      <h3 class="name-product">Sac en papier</h3>
      <p>5 000 Fcfa</p>

    </div>

    <div class="col-md-3 article" align="center">
      <div class="img-content">
        <img src="https://image.freepik.com/free-psd/paper-bag-mockup_35913-1727.jpg" alt="" width="100%" height="100%">
      </div>
      <h3 class="name-product">Sac en papier</h3>
      <p>5 000 Fcfa</p>

    </div>

    <div class="col-md-3 article" align="center">
      <div class="img-content">
        <img src="https://image.freepik.com/free-psd/paper-bag-mockup_35913-1727.jpg" alt="" width="100%" height="100%">
      </div>
      <h3 class="name-product">Sac en papier</h3>
      <p>5 000 Fcfa</p>

    </div>

    <div class="col-md-3 article" align="center">
      <div class="img-content">
        <img src="https://image.freepik.com/free-psd/paper-bag-mockup_35913-1727.jpg" alt="" width="100%" height="100%">
      </div>
      <h3 class="name-product">Sac en papier</h3>
      <p>5 000 Fcfa</p>

    </div>

    <div class="col-md-3 article" align="center">
      <div class="img-content">
        <img src="https://image.freepik.com/free-psd/paper-bag-mockup_35913-1727.jpg" alt="" width="100%" height="100%">
      </div>
      <h3 class="name-product">Sac en papier</h3>
      <p>5 000 Fcfa</p>

    </div>

    <div class="col-md-3 article" align="center">
      <div class="img-content">
        <img src="https://image.freepik.com/free-psd/paper-bag-mockup_35913-1727.jpg" alt="" width="100%" height="100%">
      </div>
      <h3 class="name-product">Sac en papier</h3>
      <p>5 000 Fcfa</p>

    </div>-->
</div>